import { useEffect, useState } from "react";
import {create as ipfsHttpClient} from 'ipfs-http-client'
import { useNavigate } from 'react-router-dom'
import './CreateNFT.scss'
import {ethers} from 'ethers';
import Web3Modal from 'web3modal'
import NFTMarketplace_ABI from '../assets/contracts/NFTMarketplace_ABI.json'
import {Buffer} from 'buffer';
import Swal from 'sweetalert2'
import Web3 from 'web3'
import Navbar from "../components/Navbar";
import Spinner from "../components/Spinner";
import { contractAddress, listingCost } from "../assets/data/contractAddress";

const projectId = '2FBvVr2KHyeIjVqu8hjBIArTkJo'
const projectSecret = '170c8919e87bf8b279b276e0ebe05aff'
const authorization = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64')

const client = ipfsHttpClient({
    url: 'https://marketplace-ultimate.infura-ipfs.io',
    headers: {
        authorization
    }
})
const ipfs = ipfsHttpClient({
    url: "https://ipfs.infura.io:5001",
    headers:{
      authorization
    }
  })
// const contractAddress = '0x13ceA4B9D2c9612C31d8fB8FDc46d4d1C146c2e7'; // new
const _tags = [
    {name: 'Art', selected: false},
    {name: 'Gaming', selected: false},
    {name: 'Photo', selected: false},
    {name: 'Sports', selected: false}
]

export default function CreateNFT() {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [walletAddress, setWalletAddress] = useState("")
    const [fileUrl, setFileUrl] = useState(null)
    const [formInput, updateFormInput] = useState({price: '', name: '', description: ''});
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [nftPrice, setNftPrice] = useState('');
    const [tags, setTags] = useState([]);

    const [needFill, setNeedFill] = useState(true);

    useEffect(()=> {
        if(name.length === 0 || description.length === 0 || nftPrice.length === 0){
            setNeedFill(true)
        }else{
            setNeedFill(false)
        }
    },[name, description, nftPrice])

    useEffect(() => {
        if(nftPrice.includes(',')){
            alert('NO COMMAS ALLOWED')
        }
    },[nftPrice])




    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })



    useEffect(() => {
        requestAccount()
        setTags(_tags);
    },[])

    async function onChange(e) {
        const file = e.target.files[0];

        const result = await ipfs.add(file);
        setFileUrl('https://marketplace-ultimate.infura-ipfs.io/ipfs/' + result.path)
    }

    async function createToken(url){
        if(nftPrice == 0){alert('Price must be higher than 0'); return}
        if(isNaN(nftPrice)){alert('Must be a number (can not contain commas or other special character'); return}
        if(description.length > 100){
            alert('Description must have less than 100 characters');
            return;
        }
        setLoading(true)
        
        const web3modal = new Web3Modal()
        const connection = await web3modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()

        let _Tags = tags.filter(element => element.selected == true);
        let _selectedTags = _Tags.map((element) => element.name)

        console.log(_selectedTags)



        let contract = new ethers.Contract(contractAddress, NFTMarketplace_ABI, signer)
        let transaction = await contract.createToken(
            fileUrl, 
            name,
            description,
            ethers.utils.parseEther(nftPrice), 
            _selectedTags,
            
            {value: ethers.utils.parseEther(listingCost)})
        await transaction.wait()

        Toast.fire({
            icon: 'success',
            title: 'NFT created and listed successfully'
        })
        setLoading(false)
        setTimeout(() => {
            navigate('/market')
        },2000)



    }


    const requestAccount = async() => {
        if(window.ethereum){
            try {
                const accounts = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
                setWalletAddress(accounts[0])

            } catch (error) {
                console.log('Error connecting...')
            }


        }else{
            console.log('Metamask not detected')
        }
    }

    const handleSelected = async(_name) => {
        setTags(tags.map((element) => element.name === _name ? {name: element.name, selected: !element.selected} : element))
    }
   


    return (
        <div className='main-container'>
            {/* NAVBAR */}
            <Navbar/>

            <div className='create-nft-container'>
                
                <h1 style={{marginBottom:50}}>Create NFT</h1>
                <p style={{margin:0, fontSize:24}}>Name</p>
                <input className="input-name" placeholder="Name" onChange={(e) => setName(e.target.value)}/>
                <p style={{margin:0, fontSize:24}}>Description</p>
                <textarea className="input-name" placeholder="Description" onChange={(e) => setDescription(e.target.value)}/>
                <p style={{margin:0, fontSize:24}}>Tags</p>
                <div id="tags">
                    {tags.map((element, i) => (
                        <p key={i} id={`${element.selected && 'selected'}`} onClick={() => handleSelected(element.name)}>{element.name}</p>
                    ))}
                </div>


                <br></br>
                <p style={{margin:0, fontSize:24}}>Price</p>
                <input className="input-price" type='number' placeholder="Price (BNB)" onChange={(e) => setNftPrice(e.target.value)}/>
                <p style={{fontWeight:200}}>Commas not allowed</p>

               
                {needFill ? <p>You must complete all the fields in order to upload your image</p> : 
                    <>
                        <p style={{margin:'40px 0px 0px 0px'}}>Select your image</p>
                        <input
                            type="file"
                            name="Asset"
                            className="my-4"
                            onChange={onChange}
                        />
                    </>
                }
                
                {fileUrl && (
                    <img className="rounded" src={fileUrl} />
                )}
                {fileUrl && 
                    <>
                        {loading ? 
                            <Spinner/>
                            :
                            <button onClick={() => createToken()}>Create NFT</button>
                        }
                    </>
                }
            </div>


        </div>
    )
}


/* API Key: 007cae50ac1e1fc793f2
 API Secret: bd9f97bb5ebd3d25f78e00b0e5deeffedc0d0a7608525aa301c41714f290c990
 JWT: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI2Zjc3YmM3My1kYWEyLTQ2MjQtODNjZS1kYmYzZGExYmQwMmUiLCJlbWFpbCI6ImZmZWxpcGVoZXJuYW5kZXp6OTcwQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfSx7ImlkIjoiTllDMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiIwMDdjYWU1MGFjMWUxZmM3OTNmMiIsInNjb3BlZEtleVNlY3JldCI6ImJkOWY5N2JiNWViZDNkMjVmNzhlMDBiMGU1ZGVlZmZlZGMwZDBhNzYwODUyNWFhMzAxYzQxNzE0ZjI5MGM5OTAiLCJpYXQiOjE2NjM5NzQwMjd9.Oktew18Zuvatq2FwWwn1knnRUl7QxWStZXMKRe3ja6g */