import React, { useState } from 'react';
import { useEffect } from 'react';
import Navbar from '../components/Navbar';
import {ethers} from 'ethers';
import Web3Modal from 'web3modal'
import { lotteryAddress } from '../assets/data/contractAddress';
import lotteryABI from '../assets/contracts/Lottery.json'
import './Lottery.scss'
import Spinner from '../components/Spinner';
import { ticketCost } from '../assets/data/contractAddress';
import Swal from 'sweetalert2';
import lotteryBackground from '../assets/images/lottery-background.jpeg'


export default function Lottery() {

    const [accountAddress, setAccountAddress] = useState(false)
    const [loadingTransaction, setLoadingTransaction] = useState(false)
    const [loading, setLoading] = useState(true)
    const [isLoged, setIsLoged] = useState(false)
    const [nfts, setNfts] = useState(null)
    const [userAddress, setUserAddress] = useState(null)
    const [balance, setBalance] = useState(0)
    const [amountTickets, setAmountTickets] = useState(0)
    const [prizePool, setPrizePool] = useState(0)
    const [number, setNumber] = useState(0)
    const [lastWinner, setLastWinner] = useState(null)
    const [lastPrize, setLastPrize] = useState(0)
    const [hasEnd, setHasEnd] = useState(true)
    const [myTickets, setMyTickets] = useState(0)
    const [manager, setManager] = useState('')



    useEffect(() => {
        getData()
    },[])

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const getData = async() => {
    
        const web3modal = new Web3Modal()
        const connection = await web3modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()
        setAccountAddress(signer.provider.provider.selectedAddress)
    
        let lotteryContract = new ethers.Contract(lotteryAddress, lotteryABI, signer);
      
        let _prizePool = await lotteryContract.getPrizePool();
        setPrizePool(_prizePool.toString())
    
        let _lastWinner = await lotteryContract.lastWinner();
        _lastWinner == null || _lastWinner == 0x0000000000000000000000000000000000000000 ? setLastWinner(null) : setLastWinner(_lastWinner)
    
        let _lastPrize = await lotteryContract.lastPrize();
        setLastPrize(Number(_lastPrize.toString()));
    
        let _hasEnd = await lotteryContract.hasEnd();
        setHasEnd(_hasEnd);

        let _manager = await lotteryContract.manager();
        setManager(_manager);
        
        let _myTickets = await lotteryContract.getMyTickets(signer.provider.provider.selectedAddress)
        setMyTickets(_myTickets.toString())

        
        setLoading(false)
      
    }

    const participate = async() => {
        setLoadingTransaction(true)
        const web3modal = new Web3Modal()
        const connection = await web3modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()

        let lotteryContract = new ethers.Contract(lotteryAddress, lotteryABI, signer);
        try {
            let transaction = await lotteryContract.enter(amountTickets, {value: ethers.utils.parseEther((Number(ticketCost)*amountTickets).toString())});
            await transaction.wait()
            console.log('bided')
            Toast.fire({
                icon: 'success',
                title: `Bided successfully`
            })
            getData()
            setLoadingTransaction(false)
            
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.message}`
            })
            setLoadingTransaction(false)
        }
    }

    const pickWinner = async() => {
        const web3modal = new Web3Modal()
        const connection = await web3modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()

        let lotteryContract = new ethers.Contract(lotteryAddress, lotteryABI, signer);
        const _pickWinner = await lotteryContract.pickWinner();
        await _pickWinner.wait()
    }
    
    return (
        <div className='main-container'>
            <Navbar/>
            <div id="lottery-container" style={{backgroundImage:`url(${lotteryBackground})`, backgroundSize:'cover'}}>
                <h1 style={{textAlign:'center'}}>Lottery</h1>
                {!hasEnd && 
                    <p style={{backgroundColor:'green', padding:'2px 9px', borderRadius:5, margin:'15px 0px'}}>Lottery is live</p>
                }
                {loading ? 
                    <Spinner/>
                :
                    <>
                        <p style={{margin:'20px 0px 0px 0px'}}>Prize Pool</p>
                        <p style={{fontSize:30}}>{ethers.utils.formatEther(prizePool)} BNB</p>
                        
                        <p>My Participation: {myTickets} tickets</p>
                        {hasEnd ? <p>Lottery has end. Waiting to restart</p> : 
                            <>
                                <input type='number' placeholder='Number of tickets' onChange={(e) => setAmountTickets(e.target.value)} />
                                <p>Ticket Cost {ticketCost} BNB</p>
                                <br></br>
                                {loadingTransaction ? 
                                    <Spinner/>
                                :
                                    <button onClick={() => participate()}>Participate</button>
                                }
                            </>
                        
                        }
                        
                        <div style={{textAlign:'center', marginTop:40}}>
                            <p style={{margin:0}}>Last Winner</p>
                            <p style={{margin:0}}>{lastWinner.slice(0,9)}...</p>
                            <br></br>
                            <p style={{margin:0}}>Last Prize</p>
                            <p style={{margin:0}}>{ethers.utils.formatEther(lastPrize.toString())} BNB</p>
                        </div>


                        {/* MANAGER AREA */}
                        {manager.toLocaleLowerCase() === accountAddress.toLocaleLowerCase() && 
                            <>
                                <p style={{marginTop:25}}>Manager Area</p>
                                <button onClick={() => pickWinner()}>Pick winner</button>
                            </>
                        }
                    </>
                }
            </div>
        </div>
    )
}
