import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import './App.scss'
import {ethers} from 'ethers';
import Web3Modal, { local } from 'web3modal'
import NFTMarketplace_ABI from './assets/contracts/NFTMarketplace_ABI.json'
import Navbar from './components/Navbar';
import Spinner from './components/Spinner';
// 0xB0FDe31897238a8e2D699485A40A46147720cdf3 cuenta propia 5 pruebas - se puede usar
import { contractAddress } from './assets/data/contractAddress';
import Swal from 'sweetalert2';

const _tags = [
  {name: 'Art', selected: false},
  {name: 'Gaming', selected: false},
  {name: 'Photo', selected: false},
  {name: 'Sports', selected: false}
]


export default function App() {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [walletAddress, setWalletAddress] = useState("")
  const [tags, setTags] = useState([])
  const [cacheNfts, setCacheNfts] = useState([])
  const [nfts, setNfts] = useState([])
  const [marketItems, setMarketItems] = useState([])
  const [marketItemsCache, setMarketItemsCache] = useState([])
  const [query, setQuery] = useState('')
  
  function order(a, b) {
    // array.sort((a,b) => a.currentBid - b.currentBid)
    return a < b ? -1 : (a > b ? 1 : 0);
  } 

  useEffect(() => {
   /*  loadNfts() */
    fireFirstAlert()

    requestAccount()
    setTags(_tags)
  },[])

  const fireFirstAlert = async() => {
    const hasFired = localStorage.getItem('firstAlert');
  /*   console.log(hasFired) */

    if(hasFired === null){
      Swal.fire(
        'We are testing the App',
        'You need to be connected to the BSC TESTNET Chain to be able to see and interact with the content',

        'warning'
      )
      Swal.fire({
        icon: 'warning',
        title: 'We are testing the App',
        text: 'You must be connected to the BSC TESTNET Chain to be able to see and interact with the content',
        footer: '<a href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" target="_blank">How to connect to BSC TESTNET Chain</a>'
      })
      localStorage.setItem('firstAlert', true);
    }
  }

  const requestAccount = async() => {
    if(window.ethereum){
     /*    console.log('detected') */

        try {
            const accounts = await window.ethereum.request({
                method: 'eth_requestAccounts',
            });
            setWalletAddress(accounts[0])
            loadNfts(accounts[0])

        } catch (error) {
            console.log('Error connecting...')
        }


    }else{
        console.log('Metamask not detected')
    }
  }

  async function loadNfts(account){
    const web3modal = new Web3Modal()
    const connection = await web3modal.connect()
    const provider = new ethers.providers.Web3Provider(connection)
    const signer = provider.getSigner()

    let contract = new ethers.Contract(contractAddress, NFTMarketplace_ABI, signer)
    let tx = await contract.fetchMarketItems();
    if(tx.length == 0){
      setLoading(false)
    }

    let _marketItems = []

    tx.forEach(async(element, i) => {
      let tmp = {}
      let tokenURI = await contract.tokenURI(element.tokenId.toString());
      
      tmp.tokenId = element.tokenId.toString();
      tmp.title = element.name;
      tmp.description = element.description;
      tmp.seller = element.seller;
      tmp.owner = element.owner;
      tmp.price = ethers.utils.formatEther(element.price.toString())
      tmp.sold = element.sold
      tmp.tokenURI = tokenURI;
      tmp.tags = element.tags

      setMarketItems(prev => [...prev, tmp])
      setMarketItemsCache(prev => [...prev, tmp])

    })
    setLoading(false)
  }

  useEffect(() => {

    if(query === 'Sort by'){
      setMarketItems(marketItemsCache)
      clearSelected()
    }
    if(query === 'High to Low'){
      const sorted = [...marketItems].sort((a,b) => 
        a.price < b.price ? 1 : -1
      )
      console.log(sorted)
      setMarketItems(sorted)
    }
    if(query === 'Low to High'){
      const sorted = [...marketItems].sort((a,b) => 
        a.price > b.price ? 1 : -1
      )
      console.log(sorted)
      setMarketItems(sorted)
    }
    /* if(_query == 'Sort by'){setNfts(NFT__DATA); return};
    if(_query == 'High Rate'){setNfts(NFT__DATA.sort((a,b) => a.currentBid - b.currentBid))} */

  },[query])


  const handleSelect = (_tag) => {
    setTags(tags.map((element) => element.name === _tag.name ? {name: element.name, selected: true} : {name: element.name, selected: false}))
    setMarketItems([...marketItemsCache].filter(element => element.tags.includes(_tag.name)))
  }
  const clearSelected = () => {
    setMarketItems(marketItemsCache)
    setTags(tags.map((element) =>  true && {name: element.name, selected: false}))
  }

  useEffect(() => {
    if(tags.length !== 0){
      const selectedTags = tags.filter(element => element.selected == true);
      
    /*   const sorted = [...marketItemsCache].filter(element => element.tags.includes(selectedTags[0].name))

      setMarketItems(sorted) */
    }
  },[tags])


  
  

  
  return (
    <div className='main-container'>
      {/* NAVBAR */}
      {/* <div className='navbar'>
        <h3>NFTs</h3>
        <div className='middle-navbar'>
        <p>Home</p>
        <p>Market</p>
        <p onClick={() => navigate('/create-nft')}>Create</p>
        </div>
        {walletAddress == '' ? 
          <button onClick={requestAccount}>Connect wallet</button>
        :
          <p style={{margin:0, userSelect:'none'}}>{walletAddress.slice(0,9)}...</p>
        }
      </div> */}
      <Navbar/>
      <div className='banner'>
        <h2>MarketPlace</h2>
      </div>

      {/* SORT QUERY */}
      <div className='sort-filters'>
        <div style={{display:'flex'}}>
         {tags.map((element, i) => (
          <p key={i} id={`${element.selected && 'selected'}`} onClick={() => handleSelect(element)}>{element.name}</p>
         ))}
         <p style={{border:'none'}} onClick={() => clearSelected()}>Clear</p>
        </div>
        <select onChange={(e) => setQuery(e.target.value)}>
          <option>Sort by</option>
          <option>Low to High</option>
          <option>High to Low</option>
        </select>
      </div>


      {/* CONTENT */}
      <div className='app-content'>
          {marketItems.length === 0 && loading === true ? 
              <div style={{width:'100%', height:'100%', placeItems:'center', placeContent:'center', display:'flex'}}>
                <Spinner/>
              </div>
            :
              <>
                {marketItems.length === 0 && 
                  <p>No NFTs on the market</p>
                }
              </>
          }
        <div className='nfts-grid'>
          {
            marketItems.map((element, i) => (
              <div key={i} className='nft-card' onClick={() => navigate(`/nft/${element.tokenId}`, {state:{props:element}})}>
                <img src={element.tokenURI}/>
                <div style={{width:'100%',textAlign:'left'}}>
                  <p style={{margin:'5px 0px'}}>{element.title}</p>
                </div>
                {/* <p style={{margin:'12px 0px 0px 0px'}}>{element.name}</p> */}
                <div className='nft-info'>
                {/*  <img src={element.creatorImg}/> */}
                  <div>
                    <p id='created-by'>Seller</p>
                    <p>{element.seller.slice(0,7)}...</p>
                  </div>
                  <div>
                    <p id='current-bid'>Current Price</p>
                    <p>{(element.price)} BNB</p>
                  </div>
                </div>
                <div className='nft-info-bottom'>
                  <button>Buy</button>
                  <p>View History</p>
                </div>

              </div>
            ))}

        </div>
      </div>

    </div>
  )
}

