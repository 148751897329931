import React from 'react'
import { useEffect, useState } from 'react'
import './NFTDetails.scss'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar';
import {ethers} from 'ethers';
import Web3Modal from 'web3modal'
import NFTMarketplace_ABI from '../assets/contracts/NFTMarketplace_ABI.json'
import Swal from 'sweetalert2';
import Spinner from '../components/Spinner';
import { contractAddress, listingCost } from '../assets/data/contractAddress';



export default function NFTDetails(data) {
    const path = window.location.pathname.split('/');
    const pathTokenId = path[path.length -1 ]
   /*  console.log(pathTokenId) */


    const navigate = useNavigate()
    const [loadingTransaction, setLoadingTransaction] = useState(false)
    const [loading, setLoading] = useState(true)
    const [walletAddress, setWalletAddress] = useState("")
    const [nftInfo, setNftInfo] = useState()
    const [nftData, setNftData] = useState()
    
   /*  const props = useLocation().state.props; */
   /*  console.log(props) */
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    useEffect(() => {
        requestAccount()
    },[])



   


    const requestAccount = async() => {
        if(window.ethereum){
            try {
                const accounts = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
                setWalletAddress(accounts[0])
                fetchInfo(accounts[0])



               /*  getOwnerOf(accounts[0])
                getNFTHistoryData() */
            } catch (error) {
                console.log('Error connecting...')
            }
        }else{
            console.log('Metamask not detected')
        }
    }

    const fetchInfo = async(account) => {
        const web3modal = new Web3Modal()
        const connection = await web3modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()

        let contract = new ethers.Contract(contractAddress, NFTMarketplace_ABI, signer)
        let _nftInfo = await contract.fetchMarketItem(pathTokenId)
        try {
            let _nftURI = await contract.tokenURI(pathTokenId.toString());


            let tmp = {}
            tmp.description = _nftInfo.description;
            tmp.owner = _nftInfo.owner;
            tmp.price = ethers.utils.formatEther(_nftInfo.price.toString());
            tmp.seller = _nftInfo.seller;
            tmp.sold = _nftInfo.sold;
            tmp.tags = _nftInfo.tags;
            tmp.title = _nftInfo.name;
            tmp.tokenId = pathTokenId;
            tmp.tokenURI = _nftURI;

            setNftInfo(tmp)

            getOwnerOf(account)
            getNFTHistoryData()
        } catch (error) {
             Toast.fire({
                icon: 'error',
                title: `Nft does not exist ):`,
                timer: 2000
            })
            return
        }



        

    }







    const getOwnerOf = async(account) => {
        const web3modal = new Web3Modal()
        const connection = await web3modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()

        let contract = new ethers.Contract(contractAddress, NFTMarketplace_ABI, signer)
        let ownerOf = await contract.ownerOf(pathTokenId)
    }

    const getNFTHistoryData = async() => {
        const web3modal = new Web3Modal()
        const connection = await web3modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()

        let contract = new ethers.Contract(contractAddress, NFTMarketplace_ABI, signer)
        let nftHistoryData = await contract.getSoldData(pathTokenId);

        setNftData(nftHistoryData)

        setLoading(false)
    }

    const buyNft = async() => {
        setLoadingTransaction(true)
        const web3modal = new Web3Modal()
        const connection = await web3modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()

        let contract = new ethers.Contract(contractAddress, NFTMarketplace_ABI, signer)
        try {
            let transaction = await contract.createMarketSale(pathTokenId, {value:ethers.utils.parseEther(nftInfo.price)})
            await transaction.wait()
            Toast.fire({
                icon: 'success',
                title: 'NFT bought successfully'
            })
            setLoadingTransaction(false)
            navigate('/my-nfts')
            
        } catch (error) {
            setLoadingTransaction(false)
            console.log('There was an error')
            Toast.fire({
                icon: 'error',
                title: `${error.message}`
            })
        }
    }

    const sellNft = async() => {
        setLoadingTransaction(true)
        const web3modal = new Web3Modal()
        const connection = await web3modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()

        let contract = new ethers.Contract(contractAddress, NFTMarketplace_ABI, signer)
        let transaction = await contract.resellToken(pathTokenId, ethers.utils.parseEther(nftInfo.price), {value:ethers.utils.parseEther(listingCost)})
        await transaction.wait()
        setLoadingTransaction(false)
        navigate('/')
    }
    
    const changePrice = async() => {
        Swal.fire({
            title: 'Your new price',
            input: 'text',
            inputPlaceholder:'New Price (BNB)',
            text:'No commas allowed',
            inputAttributes: {
              autocapitalize: 'off',
            },
            showCancelButton: true,
            confirmButtonText: 'Modify price',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async(result) => {
            if (result.isConfirmed) {
                console.log(result.value)
     
                setLoadingTransaction(true)
                const web3modal = new Web3Modal()
                const connection = await web3modal.connect()
                const provider = new ethers.providers.Web3Provider(connection)
                const signer = provider.getSigner()
                
                let contract = new ethers.Contract(contractAddress, NFTMarketplace_ABI, signer)

                try {
                    let transaction = await contract.changePrice(pathTokenId, ethers.utils.parseEther(result.value))
                    await transaction.wait()
                    setLoadingTransaction(false)
                    navigate(0)
                    
                } catch (error) {
                    Toast.fire({
                        icon: 'error',
                        title: `${error.message}`
                    })
                    setLoadingTransaction(false)
                    
                }
            }
        })

    }

    return (
        <div className='main-container'>
            <Navbar/>
            <div className='nft-details-container'>
                {loading ? <Spinner/> : 
                <>
                    <div id="image-container">
                        <img src={nftInfo.tokenURI}/>

                    </div>
                    <div id='nft-details'>
                        <h2>{nftInfo.title}</h2>
                        <p id="description">{nftInfo.description}</p>
                        <div id="tags">
                            {nftInfo.tags.map((element, i) => (
                                <p id="tag" key={i}>{element}</p>
                            ))}
                        </div>
                        {nftInfo.sold ? 
                            <p id="current-price">Last price</p>
                        :
                            <p id="current-price">Current price</p>
                        }
                        <p id="price">{nftInfo.price} BNB</p>
                        {nftInfo.sold ? 
                            <>
                                {nftInfo.owner.toLowerCase() == walletAddress.toLowerCase() ? 
                                    <>
                                        {loadingTransaction ? 
                                            <Spinner/>
                                        :
                                            <button onClick={() => sellNft()}>Sell</button>
                                        }
                                    </>
                                :
                                    <p style={{fontSize:25}}>Sold</p>
                                }
                            </>
                        :
                            <>  
                                {loadingTransaction ? 
                                    <Spinner/>
                                :
                                    <>
                                        <button onClick={() => buyNft()}>Buy</button>
                                        <button style={{marginLeft:5}} onClick={() => changePrice()}>Modify Price</button>
                                    </>
                                }
                            </>
                        }
                        
                        {loading === false && nftData.length !== 0 && 
                            <>
                            <p style={{marginTop:20, marginBottom:5}}>History</p>
                            <div id="nft-history-data-container">
                                {loading ? 
                                    <Spinner/>
                                    :
                                    nftData.map((element, i) => (
                                        <div id="nft-history-data" key={i}>
                                            <p>
                                                Last Sold Date: {new Date(Number(element.timestamp.toString()) * 1000).toLocaleDateString("en-US")} {new Date(Number(element.timestamp.toString()) * 1000).toLocaleTimeString("en-US")}
                                            </p>
                                            <p>Last Price Sold: {ethers.utils.formatEther(element.lastPriceSold)} BNB</p>
                                            <p>Last Buyer: {element.lastBuyer.slice(0,9)}...</p>
                                        </div>
                                    ))
                                }
                            </div>
                        
                            </>
                        }


                    </div>
                </>}
            </div>
        </div>
    )
}
