import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateNFT from './pages/CreateNFT';
import NFTDetails from './pages/NFTDetails';
import MyNFTs from './pages/MyNFTs';
import Home from './pages/Home';
import Lottery from './pages/Lottery';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter /* basename="/bestartnft" */>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/market" element={<App />}/>
      <Route path="/create-nft" element={<CreateNFT />}/>
      <Route path="/nft/:id" element={<NFTDetails />}/>
      <Route path="/my-nfts" element={<MyNFTs />}/>
      <Route path="/lottery" element={<Lottery />}/>
    </Routes>
  </BrowserRouter>
);
