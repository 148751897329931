import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import greenDot from '../assets/images/green-dot.png'
import Announcement from './Announcement'
import './Navbar.scss'
import logo from '../assets/images/LOGO2.png'

export default function Navbar() {
    const navigate = useNavigate()
    const [walletAddress, setWalletAddress] = useState("")

    useEffect(() => {
        requestAccount()
    },[])

    const requestAccount = async() => {
        if(window.ethereum){
            try {
                const accounts = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
                setWalletAddress(accounts[0])

            } catch (error) {
                console.log('Error connecting...')
            }
        }else{
            console.log('Metamask not detected')
        }
    }


  return (
    <div id="navbar-main-container">
        <Announcement/>
        <div className='navbar'>
            {/* <h3>Best Art NFT</h3> */}
            <img src={logo}/>
            <div className='middle-navbar'>
                <p onClick={() => navigate('/')}>Home</p>
                <p onClick={() => navigate('/market')}>Market</p>
                <p onClick={() => navigate('/create-nft')}>Create</p>
                <p onClick={() => navigate('/lottery')}>Lottery</p>
            </div>
            {walletAddress == '' ? 
                <button onClick={requestAccount}>Connect wallet</button>
            :
                <div id="address" style={{display:'flex', placeItems:'center'}}>
                    <img style={{width:10, height:10, marginRight:5}} src={greenDot}/>
                    <p style={{margin:0, userSelect:'none'}} onClick={() => navigate('/my-nfts')}>{walletAddress.slice(0,9)}...</p>
                </div>
            }
        </div>
    </div>
  )
}
