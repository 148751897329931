import React from 'react'
import Navbar from '../components/Navbar'
import './Home.scss'
import nftsCollage from '../assets/images/nfts-collage.jpeg'
import { useNavigate } from 'react-router-dom'
import lotteryImage from '../assets/images/lottery.png'
import winnerGold from '../assets/images/winner-gold.png'
import backgroundImage from '../assets/images/background-2-overlay.jpg'


export default function Home() {
    const navigate = useNavigate()
    return (
        <div className='main-container' >
            <Navbar/>
            <div id="container-1">

                <div id="home-info">
                    <h1>Explore, collect, and sell NFTs</h1>
                    <p>Mint, trade and Earn</p>
                    <button onClick={() => navigate('/market')}>Launch App Now</button>
                </div>
                <div id="home-image">
                    <img src={nftsCollage} />
                </div>
            </div>
            <div id='container-2' style={{backgroundImage:`url(${backgroundImage})`, backgroundSize:'cover'}}>
                <div>
                    <h2>Get rewards by buying and selling NFT's</h2>
                    <p style={{marginBottom: 0}}>Create, buy or sell nfts on Best Art NFT and you will be entered<br></br> into a contest with a prize of $1,000.</p>
                    <p>Prize will be sent to the winner on October 25.</p>
                    <img src={winnerGold}/>
                </div>
                <div>
                    <h2>Lottery is open!</h2>
                    <p style={{marginBottom:0}}>Win prizes every week with our decentralized lottery.</p>
                    <p style={{marginBottom:40}}>Winner is choosen every Sunday between 8:00 pm to 12:00 pm.</p>
                    <img src={lotteryImage}/>
                    <button onClick={() => navigate('/lottery')}>Enter</button>
                </div>
            </div>
        </div>
    )
}
