import React from 'react'
import { useEffect } from 'react'
import Navbar from '../components/Navbar'
import './MyNFTs.scss'
import {ethers} from 'ethers';
import Web3Modal from 'web3modal'
import NFTMarketplace_ABI from '../assets/contracts/NFTMarketplace_ABI.json'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { contractAddress } from '../assets/data/contractAddress';


export default function MyNFTs() {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [myNfts, setMyNfts] = useState([])
    const [myNftsOnSale, setMyNftsOnSale] = useState([])
    useEffect(() => {
        loadMyNFTs()
    },[])

    
    const loadMyNFTs = async() => {
      const web3modal = new Web3Modal()
      const connection = await web3modal.connect()
      const provider = new ethers.providers.Web3Provider(connection)
      const signer = provider.getSigner()
  
      let contract = new ethers.Contract(contractAddress, NFTMarketplace_ABI, signer)
      let tx = await contract.fetchMyNFTs();
      let txOnSale = await contract.fetchItemsListed();

      let myNftsArray = []

      tx.forEach(async(element) => {

        let tokenURI = await contract.tokenURI(element.tokenId.toString());

        let tmp = {}
        tmp.tokenId = element.tokenId.toString();
        tmp.title = element.name;
        tmp.description = element.description;
        tmp.seller = element.seller;
        tmp.owner = element.owner;
        tmp.price = ethers.utils.formatEther(element.price.toString())
        tmp.sold = element.sold
        tmp.tokenURI = tokenURI;
        tmp.tags = element.tags;

        setMyNfts(prev => [...prev, tmp])
          
      })
      
      let myNftsOnSaleArray = []
      
      txOnSale.forEach(async(element) => {

        let tokenURI = await contract.tokenURI(element.tokenId.toString());

        let tmp = {}
        tmp.tokenId = element.tokenId.toString();
        tmp.title = element.name;
        tmp.description = element.description;
        tmp.seller = element.seller;
        tmp.owner = element.owner;
        tmp.price = ethers.utils.formatEther(element.price.toString())
        tmp.sold = element.sold
        tmp.tokenURI = tokenURI;
        tmp.tags = element.tags;

        setMyNftsOnSale(prev => [...prev, tmp])
          
      })
      
      setTimeout(() => {
        setLoading(false)
      },500)

    }
    
    return (
        <div className='main-container'>
            <Navbar/>
            <div className='my-nfts-container'>
                <div className='app-content'>
                    {myNfts.length !== 0 && 
                      <>
                        <h2 style={{marginTop:50, textAlign:'center'}}>My purchased NFTs</h2>
                        <div className='nfts-grid'>
                            {myNfts.map((element, i) => (
                                <div key={i} className='nft-card' onClick={() => navigate(`/nft/${element.tokenId}`, {state:{props:element}})}>
                                <img src={element.tokenURI}/>
                                <div style={{width:'100%',textAlign:'left'}}>
                                  <p style={{margin:0}}>{element.title}</p>
                                </div>
                                <p style={{margin:'12px 0px 0px 0px'}}>{element.name}</p>
                                <div className='nft-info'>
                                {/*  <img src={element.creatorImg}/> */}
                                  <div>
                                    <p id='created-by'>Owner</p>
                                    <p>{element.owner.slice(0,7)}...</p>
                                  </div>
                                  <div>
                                    <p id='current-bid'>Last price</p>
                                    <p>{(element.price)} BNB</p>
                                  </div>
                                </div>
                                <div className='nft-info-bottom'>
                                  <button>Sell</button>
                                  <p>View History</p>
                                </div>
              
                              </div>
                            ))}
                        </div>
                      </>
                    }

                    {myNftsOnSale.length !== 0 && 
                      <>
                        <h2 style={{marginTop:50, textAlign:'center'}}>My NFTs on sale</h2>
                        <div className='nfts-grid'>
                            {myNftsOnSale.map((element, i) => (
                                <div key={i} className='nft-card' onClick={() => navigate(`/nft/${element.tokenId}`, {state:{props:element}})}>
                                <img src={element.tokenURI}/>
                                <div style={{width:'100%',textAlign:'left'}}>
                                  <p style={{margin:0}}>{element.title}</p>
                                </div>
                                <p style={{margin:'12px 0px 0px 0px'}}>{element.name}</p>
                                <div className='nft-info'>
                                {/*  <img src={element.creatorImg}/> */}
                                  <div>
                                    <p id='created-by'>Seller</p>
                                    <p>{element.seller.slice(0,7)}...</p>
                                  </div>
                                  <div>
                                    <p id='current-bid'>Last price</p>
                                    <p>{(element.price)} BNB</p>
                                  </div>
                                </div>
                                <div className='nft-info-bottom'>
                                  <button>Buy</button>
                                  <p>View History</p>
                                </div>
              
                              </div>
                            ))}
                        </div>
                      </>
                    }
                    
                    {myNfts.length === 0 && myNftsOnSale.length === 0 && loading === false &&
                      <p style={{textAlign:'center', marginTop:100, fontSize:30}}>You don't own any NFT... yet 😉</p>
                    }
                </div>
            </div>
        </div>
    )
}
